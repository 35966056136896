import { CHIPS_MIN_02 } from './currencies/min02';
import { CHIPS_MIN_025 } from './currencies/min025';
import { CHIPS_MIN_05 } from './currencies/min05';
import { CHIPS_MIN_1 } from './currencies/min1';
import { CHIPS_MIN_10 } from './currencies/min10';
import { CHIPS_MIN_100 } from './currencies/min100';
import { CHIPS_MIN_10k } from './currencies/min10k';
import { CHIPS_MIN_1k } from './currencies/min1k';
import { CHIPS_MIN_2 } from './currencies/min2';
import { CHIPS_MIN_20, CHIPS_MIN_20_v2 } from './currencies/min20';
import { CHIPS_MIN_200 } from './currencies/min200';
import { CHIPS_MIN_20k } from './currencies/min20k';
import { CHIPS_MIN_25, CHIPS_MIN_25_v2 } from './currencies/min25';
import { CHIPS_MIN_250 } from './currencies/min250';
import { CHIPS_MIN_2_5 } from './currencies/min2_5';
import { CHIPS_MIN_2_5k } from './currencies/min2_5k';
import { CHIPS_MIN_2k, CHIPS_MIN_2k_v2 } from './currencies/min2k';
import { CHIPS_MIN_300 } from './currencies/min300';
import { CHIPS_MIN_5 } from './currencies/min5';
import { CHIPS_MIN_50, CHIPS_MIN_50_v2, CHIPS_MIN_50_v3 } from './currencies/min50';
import { CHIPS_MIN_500, CHIPS_MIN_500_v2 } from './currencies/min500';
import { CHIPS_MIN_50k } from './currencies/min50k';
import { CHIPS_MIN_5k, CHIPS_MIN_5k_v2 } from './currencies/min5k';
import { ChipType } from './types';

export const CHIPS_CURRENCIES: { [key in string]: ChipType[] } = {
  USD: CHIPS_MIN_05,
  EUR: CHIPS_MIN_05,
  PHP: CHIPS_MIN_20,
  INR: CHIPS_MIN_50_v2,
  CNY: CHIPS_MIN_2_5,
  THB: CHIPS_MIN_20,
  AED: CHIPS_MIN_2,
  AMD: CHIPS_MIN_200,
  AOA: CHIPS_MIN_500,
  ARS: CHIPS_MIN_500,
  AUD: CHIPS_MIN_1,
  AZN: CHIPS_MIN_1,
  BAM: CHIPS_MIN_1,
  BDT: CHIPS_MIN_50_v3,
  BGN: CHIPS_MIN_1,
  BHD: CHIPS_MIN_02,
  BIF: CHIPS_MIN_1k,
  BND: CHIPS_MIN_05,
  BOB: CHIPS_MIN_2_5,
  BRL: CHIPS_MIN_2_5,
  BWP: CHIPS_MIN_5,
  BYN: CHIPS_MIN_1,
  CAD: CHIPS_MIN_05,
  CDF: CHIPS_MIN_1k,
  CHF: CHIPS_MIN_05,
  CLP: CHIPS_MIN_500,
  COP: CHIPS_MIN_2k,
  CRC: CHIPS_MIN_200,
  CUP: CHIPS_MIN_10,
  CVE: CHIPS_MIN_50,
  CZK: CHIPS_MIN_10,
  DJF: CHIPS_MIN_100,
  DKK: CHIPS_MIN_2_5,
  DOP: CHIPS_MIN_25,
  DZD: CHIPS_MIN_50,
  EGP: CHIPS_MIN_25,
  ETB: CHIPS_MIN_25,
  GBP: CHIPS_MIN_05,
  GEL: CHIPS_MIN_1,
  GHS: CHIPS_MIN_5,
  GMD: CHIPS_MIN_20,
  KHR: CHIPS_MIN_2k,
  GTQ: CHIPS_MIN_2_5,
  HKD: CHIPS_MIN_2_5,
  HNL: CHIPS_MIN_10,
  HTG: CHIPS_MIN_50,
  HUF: CHIPS_MIN_200,
  MGA: CHIPS_MIN_2_5k,
  ILS: CHIPS_MIN_2,
  IQD: CHIPS_MIN_500,
  PYG: CHIPS_MIN_2_5k,
  ISK: CHIPS_MIN_50,
  JOD: CHIPS_MIN_025,
  JPY: CHIPS_MIN_50,
  KES: CHIPS_MIN_50,
  KGS: CHIPS_MIN_50,
  GNF: CHIPS_MIN_5k,
  KMF: CHIPS_MIN_200,
  KRW: CHIPS_MIN_500_v2,
  KWD: CHIPS_MIN_02,
  KZT: CHIPS_MIN_200,
  UZS: CHIPS_MIN_5k_v2,
  LKR: CHIPS_MIN_100,
  LRD: CHIPS_MIN_100,
  LSL: CHIPS_MIN_10,
  LYD: CHIPS_MIN_2_5,
  MAD: CHIPS_MIN_5,
  MDL: CHIPS_MIN_10,
  IDR: CHIPS_MIN_5k,
  MKD: CHIPS_MIN_20,
  MMK: CHIPS_MIN_2k_v2,
  MRU: CHIPS_MIN_20,
  MUR: CHIPS_MIN_20,
  MVR: CHIPS_MIN_5,
  MWK: CHIPS_MIN_1k,
  MXN: CHIPS_MIN_10,
  MYR: CHIPS_MIN_2_5,
  MZN: CHIPS_MIN_25,
  NAD: CHIPS_MIN_10,
  NGN: CHIPS_MIN_1k,
  NIO: CHIPS_MIN_20,
  NOK: CHIPS_MIN_5,
  NPR: CHIPS_MIN_50,
  NZD: CHIPS_MIN_1,
  OMR: CHIPS_MIN_02,
  PAB: CHIPS_MIN_05,
  PEN: CHIPS_MIN_2,
  PKR: CHIPS_MIN_100,
  PLN: CHIPS_MIN_2,
  VND: CHIPS_MIN_10k,
  QAR: CHIPS_MIN_2,
  RON: CHIPS_MIN_2_5,
  RSD: CHIPS_MIN_50,
  RUB: CHIPS_MIN_50_v3,
  RWF: CHIPS_MIN_500,
  SAR: CHIPS_MIN_2,
  SCR: CHIPS_MIN_5,
  SDG: CHIPS_MIN_250,
  SEK: CHIPS_MIN_5,
  SGD: CHIPS_MIN_05,
  SOS: CHIPS_MIN_250,
  SVC: CHIPS_MIN_5,
  SZL: CHIPS_MIN_10,
  TJS: CHIPS_MIN_5,
  TMT: CHIPS_MIN_2,
  TND: CHIPS_MIN_1,
  TRY: CHIPS_MIN_20_v2,
  TTD: CHIPS_MIN_2_5,
  TWD: CHIPS_MIN_10,
  TZS: CHIPS_MIN_1k,
  UAH: CHIPS_MIN_20,
  UYU: CHIPS_MIN_20,
  IRR: CHIPS_MIN_20k,
  LBP: CHIPS_MIN_50k,
  XAF: CHIPS_MIN_200,
  XOF: CHIPS_MIN_300,
  ZAR: CHIPS_MIN_10,
  ZMW: CHIPS_MIN_10,
  PRB: CHIPS_MIN_10,
  VES: CHIPS_MIN_25_v2,
};
