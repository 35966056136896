import React, { Suspense } from 'react';
import { isMobileOnly, isTablet, useMobileOrientation, MobileView } from 'react-device-detect';
import { Device } from 'types';
// import { AppPortrait } from 'AppPortrait';
// import { AppLandscape } from 'AppLandscape';
import { useDispatch } from 'react-redux';
import { setDeviceOrientation } from 'core/widgets/Auth/actions';
import { getAspectRatioIphone, isOldBrowserIOS } from './getDefineDevEnv';

export enum PlatformType {
  Desktop = 'DESKTOP',
  Mobile = 'MOBILE',
  Other = 'OTHER',
}

const AppPortrait = React.lazy(() => import('./../AppPortrait')); // Lazy load portrait component
const AppLandscape = React.lazy(() => import('./../AppLandscape')); // Lazy load landscape component

// TODO add func-arguments (device-components) and replace all Responsive.tsx-components with hook
export const useDevice = () => {
  const { isLandscape, isPortrait } = useMobileOrientation();
  const isTabletLandscape = isTablet && isLandscape;
  const isTabletPortrait = isTablet && isPortrait;
  const isMobileLandscape = isMobileOnly && isLandscape;
  const isMobilePortrait = isMobileOnly && isPortrait;

  const dispatch = useDispatch();

  if (isMobileLandscape) {
    dispatch(setDeviceOrientation(true));
  }

  if (isMobilePortrait) {
    dispatch(setDeviceOrientation(false));
  }

  if (isMobileLandscape || isMobilePortrait)
    return {
      rootDeviceComponent: (
        <Suspense fallback={<></>}>
          <AppPortrait deviceType={Device.Mobile} />
        </Suspense>
      ),
      platformType: PlatformType.Mobile,
    };
  if (isTabletPortrait)
    return {
      rootDeviceComponent: (
        <Suspense fallback={<></>}>
          <AppPortrait deviceType={Device.Tablet_Portrait} />
        </Suspense>
      ),
      platformType: PlatformType.Other,
    };
  if (isTabletLandscape)
    return {
      rootDeviceComponent: (
        <Suspense fallback={<></>}>
          <AppLandscape />
        </Suspense>
      ),
      platformType: PlatformType.Other,
    };
  return {
    rootDeviceComponent: (
      <Suspense fallback={<></>}>
        <AppLandscape />
      </Suspense>
    ),
    platformType: PlatformType.Desktop,
  };
};
