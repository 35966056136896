import audioVoiceRed from 'assets/sounds/voices/english/red_wins.wav';
import audioVoiceRedFR from 'assets/sounds/voices/french/red_wins.wav';
import audioVoiceRedCH from 'assets/sounds/voices/chinese/red_wins.wav';
import audioVoiceRedSP from 'assets/sounds/voices/spanish/red_wins.wav';
import audioVoiceRedRU from 'assets/sounds/voices/russian/red_wins.wav';
import audioVoiceRedHI from 'assets/sounds/voices/hindi/red_wins.wav';
import audioVoiceRedPT from 'assets/sounds/voices/portuguese/red_wins.wav';
import audioVoiceRedTL from 'assets/sounds/voices/tagalog/red_wins.wav';
import audioVoiceRedID from 'assets/sounds/voices/bahasa/red_wins.wav';
import audioVoiceRedTR from 'assets/sounds/voices/turkish/red_wins.wav';
import audioVoiceRedDE from 'assets/sounds/voices/german/red_wins.wav';
import audioVoiceRedIT from 'assets/sounds/voices/italian/red_wins.mp3';
import audioVoiceRedCS from 'assets/sounds/voices/czech/red_wins.mp3';
import audioVoiceRedEL from 'assets/sounds/voices/greek/red_wins.mp3';

import audioVoiceBlue from 'assets/sounds/voices/english/blue_wins.wav';
import audioVoiceBlueFR from 'assets/sounds/voices/french/blue_wins.wav';
import audioVoiceBlueCH from 'assets/sounds/voices/chinese/blue_wins.wav';
import audioVoiceBlueSP from 'assets/sounds/voices/spanish/blue_wins.wav';
import audioVoiceBlueRU from 'assets/sounds/voices/russian/blue_wins.wav';
import audioVoiceBlueHI from 'assets/sounds/voices/hindi/blue_wins.wav';
import audioVoiceBluePT from 'assets/sounds/voices/portuguese/blue_wins.wav';
import audioVoiceBlueTL from 'assets/sounds/voices/tagalog/blue_wins.wav';
import audioVoiceBlueID from 'assets/sounds/voices/bahasa/blue_wins.wav';
import audioVoiceBlueTR from 'assets/sounds/voices/turkish/blue_wins.wav';
import audioVoiceBlueDE from 'assets/sounds/voices/german/blue_wins.wav';
import audioVoiceBlueIT from 'assets/sounds/voices/italian/blue_wins.mp3';
import audioVoiceBlueCS from 'assets/sounds/voices/czech/blue_wins.mp3';
import audioVoiceBlueEL from 'assets/sounds/voices/greek/blue_wins.mp3';

import audioVoiceGold from 'assets/sounds/voices/english/gold_wins.wav';
import audioVoiceGoldFR from 'assets/sounds/voices/french/gold_wins.wav';
import audioVoiceGoldCH from 'assets/sounds/voices/chinese/gold_wins.wav';
import audioVoiceGoldSP from 'assets/sounds/voices/spanish/gold_wins.wav';
import audioVoiceGoldRU from 'assets/sounds/voices/russian/gold_wins.wav';
import audioVoiceGoldHI from 'assets/sounds/voices/hindi/gold_wins.wav';
import audioVoiceGoldPT from 'assets/sounds/voices/portuguese/gold_wins.wav';
import audioVoiceGoldTL from 'assets/sounds/voices/tagalog/gold_wins.wav';
import audioVoiceGoldID from 'assets/sounds/voices/bahasa/gold_wins.wav';
import audioVoiceGoldTR from 'assets/sounds/voices/turkish/gold_wins.wav';
import audioVoiceGoldDE from 'assets/sounds/voices/german/gold_wins.wav';
import audioVoiceGoldIT from 'assets/sounds/voices/italian/gold_wins.mp3';
import audioVoiceGoldCS from 'assets/sounds/voices/czech/gold_wins.mp3';
import audioVoiceGoldEL from 'assets/sounds/voices/greek/gold_wins.mp3';

import audioVoiceWin from 'assets/sounds/voices/english/congratulations_to_winners.wav';
import audioVoiceWinFR from 'assets/sounds/voices/french/congratulations_to_the_winners.wav';
import audioVoiceWinCH from 'assets/sounds/voices/chinese/congratulations_to_the_winners.wav';
import audioVoiceWinSP from 'assets/sounds/voices/spanish/congratulations_to_the_winners.wav';
import audioVoiceWinRU from 'assets/sounds/voices/russian/congratulations_to_the_winners.wav';
import audioVoiceWinHI from 'assets/sounds/voices/hindi/congratulations_to_the_winners.wav';
import audioVoiceWinPT from 'assets/sounds/voices/portuguese/congratulations_to_the_winners.wav';
import audioVoiceWinTL from 'assets/sounds/voices/tagalog/congratulations_to_the_winners.wav';
import audioVoiceWinID from 'assets/sounds/voices/bahasa/congratulations_to_the_winners.wav';
import audioVoiceWinTR from 'assets/sounds/voices/turkish/congratulations_to_the_winners.wav';
import audioVoiceWinDE from 'assets/sounds/voices/german/congratulations_to_the_winners.wav';
import audioVoiceWinIT from 'assets/sounds/voices/italian/congratulations_to_the_winners.mp3';
import audioVoiceWinCS from 'assets/sounds/voices/czech/congratulations_to_the_winners.mp3';
import audioVoiceWinEL from 'assets/sounds/voices/greek/congratulations_to_the_winners.mp3';

import { LANGUAGES } from 'i18n';

export const redSounds: { [key in LANGUAGES]: string } = {
  [LANGUAGES.en]: audioVoiceRed,
  [LANGUAGES.zh]: audioVoiceRedCH,
  [LANGUAGES.fr]: audioVoiceRedFR,
  [LANGUAGES.es]: audioVoiceRedSP,
  [LANGUAGES.ru]: audioVoiceRedRU,
  [LANGUAGES.hi]: audioVoiceRedHI,
  [LANGUAGES.pt]: audioVoiceRedPT,
  [LANGUAGES.tl]: audioVoiceRedTL,
  [LANGUAGES.id]: audioVoiceRedID,
  [LANGUAGES.tr]: audioVoiceRedTR,
  [LANGUAGES.de]: audioVoiceRedDE,
  [LANGUAGES.el]: audioVoiceRedEL,
  [LANGUAGES.it]: audioVoiceRedIT,
  [LANGUAGES.cs]: audioVoiceRedCS,
};

export const goldSounds: { [key in LANGUAGES]: string } = {
  [LANGUAGES.en]: audioVoiceGold,
  [LANGUAGES.zh]: audioVoiceGoldCH,
  [LANGUAGES.fr]: audioVoiceGoldFR,
  [LANGUAGES.es]: audioVoiceGoldSP,
  [LANGUAGES.ru]: audioVoiceGoldRU,
  [LANGUAGES.hi]: audioVoiceGoldHI,
  [LANGUAGES.pt]: audioVoiceGoldPT,
  [LANGUAGES.tl]: audioVoiceGoldTL,
  [LANGUAGES.id]: audioVoiceGoldID,
  [LANGUAGES.tr]: audioVoiceGoldTR,
  [LANGUAGES.de]: audioVoiceGoldDE,
  [LANGUAGES.el]: audioVoiceGoldEL,
  [LANGUAGES.it]: audioVoiceGoldIT,
  [LANGUAGES.cs]: audioVoiceGoldCS,
};

export const blueSounds: { [key in LANGUAGES]: string } = {
  [LANGUAGES.en]: audioVoiceBlue,
  [LANGUAGES.zh]: audioVoiceBlueCH,
  [LANGUAGES.fr]: audioVoiceBlueFR,
  [LANGUAGES.es]: audioVoiceBlueSP,
  [LANGUAGES.ru]: audioVoiceBlueRU,
  [LANGUAGES.hi]: audioVoiceBlueHI,
  [LANGUAGES.pt]: audioVoiceBluePT,
  [LANGUAGES.tl]: audioVoiceBlueTL,
  [LANGUAGES.id]: audioVoiceBlueID,
  [LANGUAGES.tr]: audioVoiceBlueTR,
  [LANGUAGES.de]: audioVoiceBlueDE,
  [LANGUAGES.el]: audioVoiceBlueEL,
  [LANGUAGES.it]: audioVoiceBlueIT,
  [LANGUAGES.cs]: audioVoiceBlueCS,
};

export const congratulationsSounds: { [key in LANGUAGES]: string } = {
  [LANGUAGES.en]: audioVoiceWin,
  [LANGUAGES.zh]: audioVoiceWinCH,
  [LANGUAGES.fr]: audioVoiceWinFR,
  [LANGUAGES.es]: audioVoiceWinSP,
  [LANGUAGES.ru]: audioVoiceWinRU,
  [LANGUAGES.hi]: audioVoiceWinHI,
  [LANGUAGES.pt]: audioVoiceWinPT,
  [LANGUAGES.tl]: audioVoiceWinTL,
  [LANGUAGES.id]: audioVoiceWinID,
  [LANGUAGES.tr]: audioVoiceWinTR,
  [LANGUAGES.de]: audioVoiceWinDE,
  [LANGUAGES.el]: audioVoiceWinEL,
  [LANGUAGES.it]: audioVoiceWinIT,
  [LANGUAGES.cs]: audioVoiceWinCS,
};
