import audioVoiceBets from 'assets/sounds/voices/english/please_place_you_bets.wav';
import audioVoiceBetsFR from 'assets/sounds/voices/french/please_place_you_bets.wav';
import audioVoiceBetsCH from 'assets/sounds/voices/chinese/please_place_you_bets.wav';
import audioVoiceBetsSP from 'assets/sounds/voices/spanish/please_place_you_bets.wav';
import audioVoiceBetsRU from 'assets/sounds/voices/russian/please_place_you_bets.wav';
import audioVoiceBetsHI from 'assets/sounds/voices/hindi/please_place_you_bets.wav';
import audioVoiceBetsPT from 'assets/sounds/voices/portuguese/please_place_you_bets.wav';
import audioVoiceBetsTL from 'assets/sounds/voices/tagalog/please_place_you_bets.wav';
import audioVoiceBetsID from 'assets/sounds/voices/bahasa/please_place_you_bets.wav';
import audioVoiceBetsTR from 'assets/sounds/voices/turkish/please_place_you_bets.wav';
import audioVoiceBetsDE from 'assets/sounds/voices/german/please_place_you_bets.wav';
import audioVoiceBetsIT from 'assets/sounds/voices/italian/please_place_you_bets.mp3';
import audioVoiceBetsCS from 'assets/sounds/voices/czech/please_place_you_bets.mp3';
import audioVoiceBetsEL from 'assets/sounds/voices/greek/please_place_you_bets.mp3';

import audioVoiceNoBets from 'assets/sounds/voices/english/no_more_bets_good_luck.wav';
import audioVoiceNoBetsFR from 'assets/sounds/voices/french/no_more_bets_good_luck.wav';
import audioVoiceNoBetsCH from 'assets/sounds/voices/chinese/no_more_bets_good_luck.wav';
import audioVoiceNoBetsSP from 'assets/sounds/voices/spanish/no_more_bets_good_luck.wav';
import audioVoiceNoBetsRU from 'assets/sounds/voices/russian/no_more_bets_good_luck.wav';
import audioVoiceNoBetsHI from 'assets/sounds/voices/hindi/no_more_bets_good_luck.wav';
import audioVoiceNoBetsPT from 'assets/sounds/voices/portuguese/no_more_bets_good_luck.wav';
import audioVoiceNoBetsTL from 'assets/sounds/voices/tagalog/no_more_bets_good_luck.wav';
import audioVoiceNoBetsID from 'assets/sounds/voices/bahasa/no_more_bets_good_luck.wav';
import audioVoiceNoBetsTR from 'assets/sounds/voices/turkish/no_more_bets_good_luck.wav';
import audioVoiceNoBetsDE from 'assets/sounds/voices/german/no_more_bets_good_luck.wav';
import audioVoiceNoBetsIT from 'assets/sounds/voices/italian/no_more_bets_good_luck.mp3';
import audioVoiceNoBetsCS from 'assets/sounds/voices/czech/no_more_bets_good_luck.mp3';
import audioVoiceNoBetsEL from 'assets/sounds/voices/greek/no_more_bets_good_luck.mp3';

import { LANGUAGES } from 'i18n';

export const placeBetsSounds: { [key in LANGUAGES]: string } = {
  [LANGUAGES.en]: audioVoiceBets,
  [LANGUAGES.zh]: audioVoiceBetsCH,
  [LANGUAGES.fr]: audioVoiceBetsFR,
  [LANGUAGES.es]: audioVoiceBetsSP,
  [LANGUAGES.ru]: audioVoiceBetsRU,
  [LANGUAGES.hi]: audioVoiceBetsHI,
  [LANGUAGES.pt]: audioVoiceBetsPT,
  [LANGUAGES.tl]: audioVoiceBetsTL,
  [LANGUAGES.id]: audioVoiceBetsID,
  [LANGUAGES.tr]: audioVoiceBetsTR,
  [LANGUAGES.de]: audioVoiceBetsDE,
  [LANGUAGES.el]: audioVoiceBetsEL,
  [LANGUAGES.it]: audioVoiceBetsIT,
  [LANGUAGES.cs]: audioVoiceBetsCS,
};

export const noMoreBetsSounds: { [key in LANGUAGES]: string } = {
  [LANGUAGES.en]: audioVoiceNoBets,
  [LANGUAGES.zh]: audioVoiceNoBetsCH,
  [LANGUAGES.fr]: audioVoiceNoBetsFR,
  [LANGUAGES.es]: audioVoiceNoBetsSP,
  [LANGUAGES.ru]: audioVoiceNoBetsRU,
  [LANGUAGES.hi]: audioVoiceNoBetsHI,
  [LANGUAGES.pt]: audioVoiceNoBetsPT,
  [LANGUAGES.tl]: audioVoiceNoBetsTL,
  [LANGUAGES.id]: audioVoiceNoBetsID,
  [LANGUAGES.tr]: audioVoiceNoBetsTR,
  [LANGUAGES.de]: audioVoiceNoBetsDE,
  [LANGUAGES.el]: audioVoiceNoBetsEL,
  [LANGUAGES.it]: audioVoiceNoBetsIT,
  [LANGUAGES.cs]: audioVoiceNoBetsCS,
};
