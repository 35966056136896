import audioVoiceTime from 'assets/sounds/voices/english/time_is_running_out.wav';
import audioVoiceTimeFR from 'assets/sounds/voices/french/time_is_running_out.wav';
import audioVoiceTimeCH from 'assets/sounds/voices/chinese/time_is_running_out.wav';
import audioVoiceTimeSP from 'assets/sounds/voices/spanish/time_is_running_out.wav';
import audioVoiceTimeRU from 'assets/sounds/voices/russian/time_is_running_out.wav';
import audioVoiceTimeHI from 'assets/sounds/voices/hindi/time_is_running_out.wav';
import audioVoiceTimePT from 'assets/sounds/voices/portuguese/time_is_running_out.wav';
import audioVoiceTimeTL from 'assets/sounds/voices/tagalog/time_is_running_out.wav';
import audioVoiceTimeID from 'assets/sounds/voices/bahasa/time_is_running_out.wav';
import audioVoiceTimeTR from 'assets/sounds/voices/turkish/time_is_running_out.wav';
import audioVoiceTimeDE from 'assets/sounds/voices/german/time_is_running_out.wav';
import audioVoiceTimeIT from 'assets/sounds/voices/italian/time_is_running_out.mp3';
import audioVoiceTimeCS from 'assets/sounds/voices/czech/time_is_running_out.mp3';
import audioVoiceTimeEL from 'assets/sounds/voices/greek/time_is_running_out.mp3';

import audioVoiceWithoutBets from 'assets/sounds/voices/english/if_you_haven_decided.wav';
import audioVoiceWithoutBetsFR from 'assets/sounds/voices/french/if_you_haven_decided.wav';
import audioVoiceWithoutBetsCH from 'assets/sounds/voices/chinese/if_you_haven_decided.wav';
import audioVoiceWithoutBetsSP from 'assets/sounds/voices/spanish/if_you_haven_decided.wav';
import audioVoiceWithoutBetsRU from 'assets/sounds/voices/russian/if_you_haven_decided.wav';
import audioVoiceWithoutBetsHI from 'assets/sounds/voices/hindi/if_you_haven_decided.wav';
import audioVoiceWithoutBetsPT from 'assets/sounds/voices/portuguese/if_you_haven_decided.wav';
import audioVoiceWithoutBetsTL from 'assets/sounds/voices/tagalog/if_you_haven_decided.wav';
import audioVoiceWithoutBetsID from 'assets/sounds/voices/bahasa/if_you_haven_decided.wav';
import audioVoiceWithoutBetsTR from 'assets/sounds/voices/turkish/if_you_haven_decided.wav';
import audioVoiceWithoutBetsDE from 'assets/sounds/voices/german/if_you_haven_decided.wav';
import audioVoiceWithoutBetsIT from 'assets/sounds/voices/italian/if_you_haven_decided.mp3';
import audioVoiceWithoutBetsCS from 'assets/sounds/voices/czech/if_you_haven_decided.mp3';
import audioVoiceWithoutBetsEL from 'assets/sounds/voices/greek/if_you_haven_decided.mp3';

import { LANGUAGES } from 'i18n';

export const timeSounds: { [key in LANGUAGES]: string } = {
  [LANGUAGES.en]: audioVoiceTime,
  [LANGUAGES.zh]: audioVoiceTimeCH,
  [LANGUAGES.fr]: audioVoiceTimeFR,
  [LANGUAGES.es]: audioVoiceTimeSP,
  [LANGUAGES.ru]: audioVoiceTimeRU,
  [LANGUAGES.hi]: audioVoiceTimeHI,
  [LANGUAGES.pt]: audioVoiceTimePT,
  [LANGUAGES.tl]: audioVoiceTimeTL,
  [LANGUAGES.id]: audioVoiceTimeID,
  [LANGUAGES.tr]: audioVoiceTimeTR,
  [LANGUAGES.de]: audioVoiceTimeDE,
  [LANGUAGES.el]: audioVoiceTimeEL,
  [LANGUAGES.it]: audioVoiceTimeIT,
  [LANGUAGES.cs]: audioVoiceTimeCS,
};

export const noBetsSounds: { [key in LANGUAGES]: string } = {
  [LANGUAGES.en]: audioVoiceWithoutBets,
  [LANGUAGES.zh]: audioVoiceWithoutBetsCH,
  [LANGUAGES.fr]: audioVoiceWithoutBetsFR,
  [LANGUAGES.es]: audioVoiceWithoutBetsSP,
  [LANGUAGES.ru]: audioVoiceWithoutBetsRU,
  [LANGUAGES.hi]: audioVoiceWithoutBetsHI,
  [LANGUAGES.pt]: audioVoiceWithoutBetsPT,
  [LANGUAGES.tl]: audioVoiceWithoutBetsTL,
  [LANGUAGES.id]: audioVoiceWithoutBetsID,
  [LANGUAGES.tr]: audioVoiceWithoutBetsTR,
  [LANGUAGES.de]: audioVoiceWithoutBetsDE,
  [LANGUAGES.el]: audioVoiceWithoutBetsEL,
  [LANGUAGES.it]: audioVoiceWithoutBetsIT,
  [LANGUAGES.cs]: audioVoiceWithoutBetsCS,
};
