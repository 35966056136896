import { Sounds } from 'core/widgets/SoundSettings/types';

class AudioService {
  audioContext: AudioContext;
  buffers: {};
  initialize: boolean;
  currentSources: any;
  playQueue: any[];
  backgroundSource: any;
  clickSource: null;
  currentGainNodes: any;
  backgroundGainNode: any;
  // backgroundMusicGainNode: any;
  soundSettings: any;
  gameSoundMute: boolean;

  constructor() {
    // @ts-ignore
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    this.buffers = {};
    this.initialize = false;
    this.playQueue = [];
    this.currentSources = {};
    this.backgroundSource = null;
    this.clickSource = null;
    this.currentGainNodes = {};
    this.backgroundGainNode = null;
    // this.backgroundMusicGainNode = null;
    this.gameSoundMute = true;
    this.soundSettings = {
      soundEffects: {
        volume: 50,
        mute: null,
      },
      digitalVoice: {
        volume: 50,
        mute: null,
      },
      music: {
        volume: 50,
        mute: null,
      },
    };
  }

  suspend() {
    this.audioContext.suspend();
  }

  resume() {
    this.audioContext.resume();
  }

  getState() {
    return this.audioContext.state;
  }

  async init() {
    if (this.audioContext.state === 'suspended') {
      await this.audioContext.resume();
    }
    this.initialize = true;
  }

  isInit(): boolean {
    return this.initialize;
  }

  async loadSound(url: string | URL | Request, soundType: string | number) {
    if (!this.initialize) return;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to load sound: ${response.status} ${response.statusText}`);
      }
      const arrayBuffer = await response.arrayBuffer();
      if (this.audioContext.decodeAudioData.length === 1) {
        const audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
        // @ts-ignore
        this.buffers[soundType] = audioBuffer;
      } else {
        this.audioContext.decodeAudioData(
          arrayBuffer,
          (audioBuffer) => {
            // @ts-ignore
            this.buffers[soundType] = audioBuffer;
          },
          (error) => {
            console.error('Error decoding audio data:', error);
          },
        );
      }
    } catch (error) {
      console.error('sound error:', soundType, error);
    }
  }

  playSound(soundType: string | number, type: Sounds, shouldComplete: boolean) {
    // @ts-ignore
    if (!this.initialize || !this.buffers[soundType]) return;

    if (this.gameSoundMute) {
      this.playQueue.shift();
      return;
    }

    if (this.soundSettings.digitalVoice.mute && type === Sounds.DIGITAL_VOICE) {
      this.playQueue.shift();
      return;
    }

    const source = this.audioContext.createBufferSource();
    const volume =
      Sounds.SOUND_EFFECTS === type
        ? this.soundSettings.soundEffects.volume
        : this.soundSettings.digitalVoice.volume;
    // @ts-ignore
    source.buffer = this.buffers[soundType];

    const gainNode = this.audioContext.createGain();
    gainNode.gain.value = volume / 100;
    source.connect(gainNode).connect(this.audioContext.destination);
    source.start();

    this.currentGainNodes[type] = gainNode;

    if (shouldComplete) {
      // this.currentGainNodes[soundType] = gainNode;
      source.onended = () => {
        delete this.currentSources[soundType];
        delete this.currentGainNodes[type];
        this.playQueue.shift();
        this.playNextInQueue();
      };
    } else {
      this.currentSources[soundType] = source;
      // this.currentGainNodes[soundType] = gainNode;
    }

    return source;
  }

  stopSound(source: { stop: () => void }) {
    if (source && source.stop) {
      source.stop();
      // delete this.currentSources[soundType];
      // delete this.currentGainNodes[soundType];
    }
  }

  enqueue(soundType: any, type: Sounds, shouldComplete = false) {
    const volume = 50;
    // @ts-ignore
    if (!this.buffers[soundType]) {
      return;
    }

    const hasSameSound = this.playQueue.find(({ soundType: sound }) => sound === soundType);

    if (hasSameSound) {
      return;
    }

    this.playQueue.push({ soundType, volume, shouldComplete, type });
    if (this.playQueue.length === 1) {
      this.playNextInQueue();
    }
  }

  playNextInQueue() {
    if (this.playQueue.length === 0) return;
    const { soundType, volume, shouldComplete, type } = this.playQueue[0];

    this.playSound(soundType, type, shouldComplete);
    if (!shouldComplete) {
      this.playQueue.shift();
    }
  }

  initSoundSettings(settings: any) {
    this.soundSettings = settings;
  }

  changeSoundSettings(gameSoundMute: boolean) {
    this.gameSoundMute = gameSoundMute;
  }

  // changeBackgroundMusicVolume(newVolume: number) {
  //   if (!this.backgroundMusicGainNode) {
  //     return;
  //   }

  //   const volume = Math.max(0, Math.min(newVolume, 100)) / 100;
  //   this.backgroundMusicGainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
  // }

  changeSoundVolumeSettings({ volume, type }: { volume: number; type: Sounds }) {
    // if (Sounds.MUSIC === type) {
    //   this.changeBackgroundMusicVolume(volume);
    //   this.soundSettings = {
    //     ...this.soundSettings,
    //     music: {
    //       volume,
    //       mute: this.soundSettings.music.mute,
    //     },
    //   };
    // }

    if (Sounds.SOUND_EFFECTS === type) {
      this.updateVolume(type, volume);
      this.updateBackgroundVolume(volume);

      this.soundSettings = {
        ...this.soundSettings,
        soundEffects: {
          volume,
          mute: this.soundSettings.soundEffects.mute,
        },
      };
      return;
    }
    if (Sounds.DIGITAL_VOICE === type) {
      this.updateVolume(type, volume);

      this.soundSettings = {
        ...this.soundSettings,
        digitalVoice: {
          volume,
          mute: this.soundSettings.digitalVoice.mute,
        },
      };
      return;
    }
  }

  changeSoundMuteSettings({ mute, type }: { mute: boolean; type: Sounds }) {
    // if (Sounds.MUSIC === type) {
    //   this.soundSettings = {
    //     ...this.soundSettings,
    //     music: {
    //       mute,
    //       volume: this.soundSettings.music.volume,
    //     },
    //   };
    //   return;
    // }

    if (Sounds.SOUND_EFFECTS === type) {
      this.soundSettings = {
        ...this.soundSettings,
        soundEffects: {
          mute,
          volume: this.soundSettings.soundEffects.volume,
        },
      };
      return;
    }
    if (Sounds.DIGITAL_VOICE === type) {
      this.soundSettings = {
        ...this.soundSettings,
        digitalVoice: {
          mute,
          volume: this.soundSettings.digitalVoice.volume,
        },
      };
      return;
    }
  }

  async playInBackground(soundType: string | number) {
    if (!this.initialize) return;

    const volume = this.soundSettings.soundEffects.volume;
    // @ts-ignore
    if (!this.initialize || !this.buffers[soundType]) return;

    const source = this.audioContext.createBufferSource();
    // @ts-ignore
    source.buffer = this.buffers[soundType];

    const gainNode = this.audioContext.createGain();
    gainNode.gain.value = volume / 100;

    source.connect(gainNode).connect(this.audioContext.destination);
    source.start();
    this.backgroundGainNode = gainNode;

    this.currentSources[soundType] = source;

    source.onended = () => {
      this.stopBackgroundSound(soundType);
      this.backgroundGainNode = undefined;
    };

    return source;
  }

  // async playInBackgroundMusic(soundType: string | number) {
  //   if (!this.initialize || !this.audioContext) {
  //     console.error('AudioContext is not initialized');
  //     return;
  //   }

  //   // @ts-ignore
  //   if (!this.buffers[soundType]) {
  //     try {
  //       const response = await fetch(audioSourceMusic);
  //       if (!response.ok) {
  //         throw new Error(`Failed to fetch audio: ${response.status} ${response.statusText}`);
  //       }

  //       const arrayBuffer = await response.arrayBuffer();

  //       if (this.audioContext.decodeAudioData.length === 1) {
  //         const audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);

  //         // @ts-ignore
  //         this.buffers[soundType] = audioBuffer;
  //       } else {
  //         await new Promise((resolve, reject) => {
  //           this.audioContext.decodeAudioData(
  //             arrayBuffer,
  //             (audioBuffer) => {
  //               // @ts-ignore
  //               this.buffers[soundType] = audioBuffer;
  //               resolve(audioBuffer);
  //             },
  //             (error) => {
  //               console.error(`Error decoding audio data for ${soundType}:`, error);
  //               reject(error);
  //             },
  //           );
  //         });
  //       }
  //     } catch (error) {
  //       console.error(`Sound error for ${soundType}:`, error);
  //       return;
  //     }
  //   }

  //   // @ts-ignore
  //   if (!this.buffers[soundType]) {
  //     console.error(`Failed to load sound for ${soundType}`);
  //     return;
  //   }

  //   const volume = this.soundSettings?.music?.volume ?? 100;

  //   const source = this.audioContext.createBufferSource();
  //   // @ts-ignore
  //   source.buffer = this.buffers[soundType];

  //   const gainNode = this.audioContext.createGain();
  //   gainNode.gain.value = volume / 100;

  //   source.connect(gainNode).connect(this.audioContext.destination);

  //   source.loop = true;
  //   source.start();

  //   this.backgroundMusicGainNode = gainNode;

  //   this.currentSources[soundType] = source;

  //   source.onended = () => {
  //     this.backgroundMusicGainNode = undefined;
  //   };

  //   return source;
  // }

  playClickSound(soundType: string | number, volume = 1) {
    if (this.clickSource) {
      // @ts-ignore
      this.clickSource?.stop();
    }

    const source = this.audioContext.createBufferSource();
    // @ts-ignore
    source.buffer = this.buffers[soundType];

    const gainNode = this.audioContext.createGain();
    gainNode.gain.value = volume / 100;

    source.connect(gainNode).connect(this.audioContext.destination);
    source.start();

    // @ts-ignore
    this.clickSource = source;
  }

  stopBackgroundSound(soundType: string | number) {
    const source = this.currentSources[soundType];
    if (source && source.stop) {
      source.stop();
      delete this.currentSources[soundType];
    }
  }

  // stopBackgroundMusicSound(soundType: string | number) {
  //   if (this.currentSources[soundType]) {
  //     const source = this.currentSources[soundType];
  //     source.stop();
  //     delete this.currentSources[soundType];
  //     this.backgroundMusicGainNode = undefined;
  //   } else {
  //     console.warn(`Music error: sound ${soundType} is not playing or already stopped.`);
  //   }
  // }

  stopAllSounds() {
    Object.keys(this.currentSources).forEach((soundType) => {
      this.stopSound(soundType as any);
    });

    this.stopBackgroundSound('timer');
    // @ts-ignore
    if (this.clickSource && this.clickSource?.stop) {
      // @ts-ignore
      this.clickSource.stop();
      this.clickSource = null;
    }
    this.playQueue = [];
  }

  removeFromQueueAndStopSound(soundType: string | number) {
    const source = this.currentSources[soundType];
    const filteredSouds = this.playQueue.filter((item) => item.soundType !== soundType);
    this.playQueue = filteredSouds;
    // @ts-ignore
    this.stopSound(source);

    // @ts-ignore
    if (this.clickSource && this.clickSource.buffer === this.buffers[soundType]) {
      // @ts-ignore
      this.clickSource.stop();
      this.clickSource = null;
    }
  }

  updateVolume(type: string | number, volume: number) {
    const gainNode = this.currentGainNodes[type];

    if (gainNode) {
      gainNode.gain.value = volume / 100;
    }
  }

  updateBackgroundVolume(volume: number) {
    if (this.backgroundGainNode) {
      this.backgroundGainNode.gain.value = volume / 100;
    }
  }

  // updateBackgroundMusicVolume(volume: number) {
  //   if (this.backgroundMusicGainNode) {
  //     this.backgroundMusicGainNode.gain.value = volume / 100;
  //   }
  // }
}

const audioService = new AudioService();

export default audioService;
