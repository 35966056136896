import { DICE_SLOTS, SLOTS } from 'types';
import { preciseAddSubtract, roundToFixed } from 'utils/roundToFix';
import { DiceBetType, BetType } from '../types';

export const getBetSum = (bets: BetType[]) =>
  bets.reduce((sum, bet) => {
    const slotsValue = preciseAddSubtract(
      preciseAddSubtract(bet[SLOTS.player], bet[SLOTS.tie], 'add'),
      bet[SLOTS.banker],
      'add',
    );
    return preciseAddSubtract(sum, slotsValue, 'add');
  }, 0);

export const getDiceBetSum = (bets: DiceBetType[]) => {
  if (!bets || bets.length === 0) {
    throw new Error('The bets array must contain at least one element');
  }

  const [betsObj] = bets;
  const numbers = Object.keys(DICE_SLOTS).map((key) => betsObj[key as DICE_SLOTS]);

  const preciseAddDicesSubtract = (numbers: number[], operation: 'add' | 'subtract') => {
    const decimalPlaces = (num: number) => {
      const match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
      if (!match) return 0;
      return Math.max(0, (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0));
    };

    const maxDecimalPlaces = Math.max(...numbers.map(decimalPlaces));
    const factor = Math.pow(10, maxDecimalPlaces);

    const intNumbers = numbers.map((num) => Math.round(num * factor));

    const resultInt = intNumbers.reduce((acc, intNum) => {
      if (operation === 'add') {
        return acc + intNum;
      } else if (operation === 'subtract') {
        return acc - intNum;
      }
      throw new Error('Unsupported operation');
    });

    const result = resultInt / factor;
    return result;
  };

  const totalSum = preciseAddDicesSubtract(numbers, 'add');
  return totalSum;
};
